<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <create-edit-cliente-dialog :item="item" v-model="editDialogOpened"
                                @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <clientes-data-table ref="dataTable"
                           @click:edit="editar"
                           @click:remove="remover"
                           :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import ClientesDataTable from "../../components/shared/datatables/ClientesDataTable";
import BaseView from "../BaseView";
import {findOne, remove} from "../../api/clientes";
import CreateEditClienteDialog from "../../components/shared/dialogs/CreateEditClienteDialog";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions} from "vuex";

export default {
  name: "index",
  components: {CreateEditClienteDialog, BaseView, ClientesDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Nome',
          column: 'nome',
          type: 'text'
        },
        {
          name: 'Sexo',
          column: 'sexo',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.ARR_SEXO
          })
        },
        {
          name: 'CPF',
          column: 'cpf',
          type: 'text',
        },
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    async editar(cliente) {
      const data = await this.getCliente(cliente.id);
      this.item = data;
      this.editDialogOpened = true;
    },

    async getCliente(id) {
      if (id){
        try {
          this.error = null;
          this.loading = true;
          const page = await findOne(id);
          let cliente = page.dados[0];
          return cliente;
        } catch (err) {
          this.error = err;
        } finally {
          this.loading = false;
        }
      }
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Cliente removida com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        this.$toast.error(`Não foi possível remover à Cliente.`, {
          timeout: 6000
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>